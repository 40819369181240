import EulaPage from "./system/_eulaPage";

export default EulaPage

export const frontmatter = {
  pageMetaData: {
    refKey: "eula",
    title: "End-User License Agreement",
    description: "End-User License Agreement",
    author: "simplebackups.com"
  }
}